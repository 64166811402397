import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Idle } from "@ng-idle/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, of, BehaviorSubject, throwError } from "rxjs";
import { map, catchError, distinctUntilChanged } from "rxjs/operators";
import { NgxPermissionsService } from "ngx-permissions";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";

import * as fromStore from "../store";
import { OrdersParserService } from "./orders-parser.service";
import { environment } from "../../environments/environment";
import { User } from "../models/user.model";
import { Contact } from "../models/contact.model";
import { GainsightService } from "./gainsight.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUser: Contact | null;
  impersonator: User | null;
  isImpersonated = false;
  redirectUrl: string;

  apiToken: string;
  apiAccount: any;
  MYNITEL_TOKEN = "mynitel_auth_token";
  MYNITEL_ACCOUNT = "mynitel_account";

  loginUrl = `${environment.apiUrl}/login`;
  impersonationUrl = `${environment.apiUrl}/impersonate`;
  forgotPasswordUrl = `${environment.apiUrl}/reset_request`;
  resetPasswordUrl = `${environment.apiUrl}/reset_password`;
  validatePasswordUrl = `${environment.apiUrl}/token_status`;
  rememberMe = false;

  private authTokenSubject = new BehaviorSubject<string>("");
  authToken$ = this.authTokenSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    private http: HttpClient,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private idle: Idle,
    private store: Store<fromStore.State>,
    private orders: OrdersParserService,
    private dialog: MatDialog,
    private gainsight: GainsightService
  ) {
    this.apiToken = localStorage.getItem(this.MYNITEL_TOKEN);
    this.apiAccount = JSON.parse(localStorage.getItem(this.MYNITEL_ACCOUNT));
  }

  get isLoggedIn(): boolean {
    return this.apiToken !== null;
  }

  get accountID(): string {
    return this.apiAccount.user.accountid;
  }

  get vigilURL(): string {
    const { Orion_UserID__c, Orion_Password__c } = this.apiAccount.user as User;
    if (Orion_UserID__c && Orion_Password__c) {
      return `http://vigil.nitelusa.com/Orion//Login.aspx?AccountID=${Orion_UserID__c}&Password=${Orion_Password__c}`;
    }
    return "";
  }

  get userSFID(): string {
    return this.apiAccount.user.Id;
  }

  impersonate(impersonationToken: string): Observable<any> {
    /* let options = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache'
      })
    }; */

    const impersonateOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${impersonationToken}`,
        "Content-Type": "application/json",
        "Cache-control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
      }),
    };

    return this.http
      .post<any>(this.impersonationUrl, {}, impersonateOptions)
      .pipe(
        map((data) => {
          if (data.auth_token) {
            this.authTokenSubject.next(data.auth_token);

            localStorage.setItem(this.MYNITEL_TOKEN, data.auth_token);
            localStorage.setItem(this.MYNITEL_ACCOUNT, JSON.stringify(data));

            this.apiToken = data.auth_token;
            this.apiAccount = data;
            this.currentUser = data.user;
            this.isImpersonated = true;
            /*  this.impersonator = data.impersonator; */

            const permissions = data.user.Permissions;
            this.permissionsService.loadPermissions(permissions);
            this.trackUser(data.user, true);

            // start watching idle session
            this.idle.watch();
            return data;
          }
          return null;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  login(uid: string, password: string, rememberMe: boolean): Observable<any> {
    return this.http
      .post<any>(this.loginUrl, { uid, password, rememberMe })
      .pipe(
        map((data) => {
          if (data.auth_token) {
            this.authTokenSubject.next(data.auth_token);
            localStorage.setItem(this.MYNITEL_TOKEN, data.auth_token);
            localStorage.setItem(this.MYNITEL_ACCOUNT, JSON.stringify(data));
            this.apiToken = data.auth_token;
            this.apiAccount = data;
            this.currentUser = data.user;

            const permissions = data.user.Permissions;
            this.permissionsService.loadPermissions(permissions);

            this.trackUser(data.user);
            if (rememberMe) {
              // Set the expiration time to the expiration of the JWT.
              const nowSeconds = Math.floor(Date.now() / 1000);
              const jwt = new JwtHelperService();
              const sessionSeconds = Math.abs(
                jwt.decodeToken(data.auth_token).exp - nowSeconds
              );

              this.idle.setIdle(sessionSeconds);
              // console.log(`Login with Remember Me: idle is now ${this.idle.getIdle()}.`);
            }

            // start watching idle session
            this.idle.watch();

            if (this.redirectUrl) {
              this.router.navigateByUrl(this.redirectUrl);
            } else {
              this.router.navigate(["/"]);
            }
          }
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  trackUser(user: User, isImpersonation: boolean = false): void {
    if (this.isLoggedIn) {
      this.gainsight.initGainsight({
        user: {
          id: user.Id,
          email: user.Email,
          isImpersonation: isImpersonation
        },
        account: { id: user.AccountId, name: user.Account.Name}
      });
    }
  }

  logout(reason = null): void {
    this.clearCache();

    if (reason) {
      this.router.navigateByUrl(`/login?logout=${reason}`);
    } else {
      this.router.navigateByUrl("/login");
    }
  }

  clearCache() {
    this.store.dispatch(new fromStore.Logout()); // clears NgRx store state
    this.dialog.closeAll();
    this.authTokenSubject.next(null);
    this.apiAccount = null;
    this.apiToken = null;
    this.currentUser = null;

    localStorage.removeItem(this.MYNITEL_TOKEN);
    localStorage.removeItem(this.MYNITEL_ACCOUNT);
    localStorage.clear();
    this.orders.clear();

    // stop watching idle session
    this.idle.stop();
  }

  forgotPassword(uid): Observable<any> {
    this.idle.stop();
    return this.http.post<any>(this.forgotPasswordUrl, { uid });
  }

  resetPassword(
    token: string,
    password: string,
    passwordConfirmation: string
  ): Observable<boolean> {
    this.idle.stop();
    localStorage.setItem("mynitel_reset_token", token);
    return this.http
      .post<boolean>(this.resetPasswordUrl, {
        password,
        passwordConfirmation,
      })
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  checkValidToken(token = "") {
    let exp: number;
    const jwt = new JwtHelperService();
    if (token) {
      exp = jwt.decodeToken(token).exp; // expiration time
    } else {
      token = localStorage.getItem(this.MYNITEL_TOKEN);
      exp = jwt.decodeToken(token).exp; // expiration time
    }
    const CURRENT_TIME = Math.floor(Date.now() / 1000);
    const isValid = exp > CURRENT_TIME;

    localStorage.getItem(this.MYNITEL_TOKEN);

    return isValid;
  }

  validateToken(token: string): Observable<boolean> {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Cache-control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "-1",
      }),
    };
    return this.http.get(this.validatePasswordUrl, options).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
