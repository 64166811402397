import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { api } from 'src/app/models/api-url.model';
import { Node } from 'src/app/models/node.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NodeService {
  constructor(private apiService: ApiService) {}

  getDownNodes(): Observable<Node[]> {
    return this.apiService.get(api.nodesDownURL);
  }
}
